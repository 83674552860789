import * as React from 'react';
import getAssetLink from '../../utils/getAssetLink';
import cl from 'classnames';

export const AnimatedVideo = ({ imageHandle, className = '' }) => {
  return (
    <video
      className={cl('animated-video-player', className)}
      autoPlay
      loop
      muted
      playsInline
      src={getAssetLink({ item: imageHandle })}
      type="video/mp4"
      onClick={e => {
        e.preventDefault();
      }}
    >
      <track kind="captions" />
    </video>
  );
};
