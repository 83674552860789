import * as React from 'react';
import styled from 'styled-components';
import { H1 } from '../Layout/SectionTypography';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { colorsV2 } from '../../style/colors-v2';
import MemoIconCheck from './Detail/icons/IconCheck';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'gatsby';
import Button from '../../components/Button';
import DolInfo from '../DolInfo';

const DescriptionItemWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  grid-template-columns: 14px 1fr;
  grid-column-gap: 8px;

  ${fromScreen(1135)} {
    grid-template-columns: 16px 1fr;
  }
  svg {
    width: 100%;
  }

  p {
    color: ${colorsV2.black100};
  }
`;
const DescriptionItem = ({ description }) => {
  return (
    <DescriptionItemWrapper>
      <MemoIconCheck
        strokeColor={colorsV2.white100}
        circleColor={colorsV2.primary100}
      />
      <Typography variant="regular/16-24" desktopVariant="regular/20-28" v2>
        {description}
      </Typography>
    </DescriptionItemWrapper>
  );
};

const InfoSectionWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;

  .description {
    color: ${colorsV2.black100};
  }

  .btn-cta {
    display: none;
    button {
      font-family: 'Inter';
      font-size: 14px;
      border-radius: 8px;
      background-color: ${colorsV2.primary100};
      font-weight: 600;
    }
  }

  ${fromScreen(768)} {
    .btn-cta {
      display: block;
    }
  }
`;
const InfoSection = ({ data }) => {
  return (
    <InfoSectionWrapper>
      <H1>{data.lines[0]}</H1>
      <div className="description">
        {data.lines.slice(1).map((text, index) => (
          <DescriptionItem key={index} description={text} />
        ))}
      </div>
      {!data.noAction && (
        <DolInfo>
          {({ dolInfo }) => (
            <div className="btn-cta fb-message">
              <Link
                target="_blank"
                href={dolInfo.messenger.href}
                rel="noreferrer noopener"
              >
                <Button>Nhắn tin tư vấn</Button>
              </Link>
            </div>
          )}
        </DolInfo>
      )}
    </InfoSectionWrapper>
  );
};

const HeaderSubPageWrapper = styled.div`
  .sub-banner {
    display: none;
  }
  ${InfoSectionWrapper} {
    padding: 42px 0;
  }

  ${fromScreen(1135)} {
    display: grid;
    grid-auto-columns: auto max-content;
    grid-auto-flow: column;
    align-items: center;

    .sub-banner {
      display: block;
    }

    ${InfoSectionWrapper} {
      padding: 16px 0;
    }
  }
`;
export const HeaderSubPage = ({ data }) => {
  const imgHandle = data.imgHandle;
  return (
    <HeaderSubPageWrapper>
      <InfoSection data={data} />
      <ProgressiveImage
        src={getAssetLinkByHandle({ handle: imgHandle })}
        placeholder={getAssetLinkByHandle({ handle: imgHandle, width: 10 })}
      >
        {src => <img className="sub-banner" src={src} alt="sub-banner" />}
      </ProgressiveImage>
    </HeaderSubPageWrapper>
  );
};
