import { graphql, useStaticQuery } from 'gatsby';
import { HeaderSubPage } from '../CourseLanding/HeaderSubPage';
import * as React from 'react';
import { withMarketingSection } from '../../components/withMarketingSection';
import { colorsV2 } from '../../style/colors-v2';

const useHeader = () =>
  useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "TechnologyLanding_Header" }) {
          content
        }
      }
    }
  `);

const DolTechnologyHeaderBase = () => {
  const data = useHeader();

  return <HeaderSubPage data={data.gcms.jsonContanier.content} />;
};

export const DolTechnologyHeader = withMarketingSection(
  DolTechnologyHeaderBase,
  {
    style: {
      background: colorsV2.primary10,
      zIndex: 2,
      padding: 0,
      borderBottom: `1px solid ${colorsV2.primary40}`
    },
    selfish: true
  }
);
