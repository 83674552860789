import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import * as React from 'react';
import { fromScreen } from '../../utils/media-query/responsive.util';

const LineSeparatorWrapper = styled.div`
  padding: 24px 0;

  ${fromScreen(1366)} {
    padding: 32px 0;
  }
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colorsV2.gray20};
`;
export const LineSeparator = () => {
  return (
    <LineSeparatorWrapper>
      <Line />
    </LineSeparatorWrapper>
  );
};
