import * as React from 'react';
import { colorsV2 } from '../../../../style/colors-v2';

function IconCheck(props) {
  const { circleColor = colorsV2.green100, strokeColor = '#fff' } = props;
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={8} fill={circleColor} />
      <path
        d="M5 8.381l2 2 4-4"
        stroke={strokeColor}
        strokeWidth={1.4}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconCheck = React.memo(IconCheck);
export default MemoIconCheck;
